<template>
  <Header :title="destination?.name" back="true" add="false"></Header>
  <div id="destination_view">
    <div class='header' :style="{ 'background-image': 'url(https://destinations.trivvel.com/destinations/' + this.$route.params.destination_id + '/default.jpg)' }">
      <div class='bottom' v-if="trip_destination?.id">
        <div class="likes">
          <a href="#" @click.prevent="$root.likeDestination(trip_destination.id)" v-bind:class="isLiked(trip_destination.likes)">
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="48" viewBox="0 0 57 48" fill="none" class="">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9858 47.9564C27.7192 47.9564 27.4636 47.8499 27.2758 47.6606L4.4158 24.8006C-1.0642 19.3206 -1.5842 10.3806 3.6458 4.67063C6.28649 1.77863 9.99508 0.0924572 13.9103 0.0036893C17.8255 -0.0850786 21.6067 1.43128 24.3758 4.20063L27.9658 7.79063L28.1958 7.60063L31.2858 4.52063C34.1062 1.70227 37.9286 0.116503 41.9158 0.110626C45.4282 0.0924845 48.8191 1.39531 51.4158 3.76063C54.3064 6.40271 55.9908 10.1121 56.0777 14.0273C56.1645 17.9425 54.6464 21.7229 51.8758 24.4906L28.6958 47.6606C28.508 47.8499 28.2524 47.9564 27.9858 47.9564ZM14.0227 3.9774C11.1864 4.04169 8.5011 5.26856 6.5958 7.37063C2.90593 11.6489 3.18642 18.0609 7.2358 22.0006L28.0158 42.7206L49.0958 21.7006C51.1094 19.702 52.2133 16.9639 52.149 14.1276C52.0847 11.2912 50.8579 8.60593 48.7558 6.70063C46.8963 5.01036 44.4686 4.08212 41.9558 4.10063C39.0309 4.11276 36.2285 5.27682 34.1558 7.34063L30.8658 10.6306C30.8139 10.6825 30.7587 10.7245 30.7039 10.766C30.6638 10.7965 30.6239 10.8268 30.5858 10.8606L25.2658 16.1806C24.7603 16.6862 24.0234 16.8836 23.3329 16.6986C22.6423 16.5135 22.1029 15.9741 21.9179 15.2836C21.7328 14.593 21.9303 13.8562 22.4358 13.3506L25.1758 10.6106L21.5958 7.03063C19.5972 5.01705 16.8591 3.91311 14.0227 3.9774Z" fill="#FFA8A8"></path>
            </svg>
          </a>
        </div>
      </div>
      <div class='bottom' v-else>
        <a class="add" @click.prevent="addDestination()"><span>+</span></a>
      </div>
    </div>
    <div class="context">
      <div class="description">
        <h1 class="title">
          {{ destination?.name }}
        </h1>
        <p>
          {{ destination?.description?.en }}
        </p>
      </div>
      <div class="photos">
        <h2>Photos</h2>
        <ul>
          <li v-for="image in destination?.images || []" v-bind:key="image.file">
            <img v-bind:src="'https://destinations.trivvel.com/destinations/' + this.$route.params.destination_id + '/thumbs/' + image.file "/>
          </li>
        </ul>
      </div>
      <div v-if="trip_destination?.user_id" class="discussion">
        <h2 class="right">Discussion ({{ trip_destination?.comment_count }})</h2>
        <div class="add">
          <textarea id="comment_text" placeholder="Write a comment"></textarea>
          <input class="post_button button" type="button" name="add" value="Post" @click="addComment(trip_destination.id)"/>
        </div>
        <div class="comments" v-for="comment in comments" :key="comment.id">
          <div class="item">
            <div class="name">{{ comment.user_name }}</div>
            <p class="comment">
             {{ comment.comment }}
            </p>
            <div class="time">{{ comment.created_at }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DestinationService from "@/services/destination.service";
import TripService from "@/services/trip.service";
import { useTripStore } from '../../../store/trip';
import { useAuthStore } from '../../../store/auth';

export default {
  data() {
    return {
      tripStore: useTripStore(),
      authStore: useAuthStore(),
      destination: {},
      trip_destination: {},
      comments: []
    }
  },
  watch: {
    'destination': function() {
      //this.loadDestination();
    },
    'tripStore.current.destinations': function() {
      //this.loadDestination();
    }
  },
  mounted() {
    var self = this;

    // Load destination data from cache
    var destination = this.tripStore.destinations.find(destination =>
      destination.id == this.$route.params.destination_id
    )

    // Load destination data from remote
    if(destination) {
      this.destination = destination;
      this.loadTripDestination();
    } else {
      this.tripStore.loadDestination(this.$route.params.destination_id);
    }

    this.tripStore.$subscribe((mutation, state) => {
      self.destination = self.tripStore.destinations.find(destination =>
        destination.id == self.$route.params.destination_id
      )

      self.loadTripDestination();
    });
  },
  methods: {
    loadTripDestination() {
      var trip_dest = this.tripStore.current.destinations.find(dest =>
        dest.destination_id == this.$route.params.destination_id
      )

      if(trip_dest) {
        this.trip_destination = trip_dest;
      }
    },
    isLiked(likes){
      return DestinationService.isLiked(likes, this.authStore.state.user.id)
    },
    addDestination(){
      this.tripStore.addDestination(this.tripStore.current.trip.id, this.destination);
    }
  }
}
</script>
<style lang="sass" scoped>
#destination_view

  h2
    color: $primary-color
    font-size: 1.6rem
    font-weight: 600
    border-bottom: 1px solid #ccc
    margin-bottom: 1rem
    margin-top: 1rem
    padding-bottom: 0.2rem
    margin-left: 1rem
    margin-right: 1rem
    text-align: left

    &.right
      text-align: right

  .header
    height: 400px
    background-size: cover
    background-position: center
    flex-direction: column
    text-align: center

    .bottom
      margin-bottom: 2rem
      margin-top: 15rem


      .add
        position: relative
        margin-top: 181px
        display: flex
        justify-content: flex-end
        height: 22px
        margin-left: 25px
        display: block
        border-radius: 60px
        padding: 10px
        height: 100px
        width: 100px
        background-color: $primary-color
        cursor: pointer

        span
          color: white
          line-height: 70px
          font-size: 7rem
          font-weight: 600

      .likes
        position: relative
        margin-top: 181px
        display: flex
        justify-content: flex-end
        height: 22px
        margin-right: 25px

        a
          border: 1px dashed #f79191
          display: block
          border-radius: 60px
          padding: 10px
          height: 100px
          width: 100px
          background-color: white

          &.liked
            border: 0
            background-color: #ea5252

            svg
              path
                fill: white

          svg
            height: 82px
            width: 58px

  .context
    padding: 0 1rem
    margin-bottom: 4rem

    .description
      margin-top: 2rem
      background-color: white
      border: 1px solid #ccc
      border-radius: 14px
      padding: 1rem

      h1
        color: $primary-color
        font-size: 2.6rem
        font-weight: 600
        margin-bottom: 1rem
        margin-top: 1rem

      p
        font-size: 1.8rem
        text-align: left

    .photos
      margin-top: 4rem

      h2
        font-size: 3rem

      ul
        display: flex
        justify-content: center
        padding: 0.5rem

        li
          display: flex
          padding: 0 0.4rem

          img
            border-radius: 10px

    .discussion

      h2
        font-size: 3rem

      .add
        margin-bottom: 2rem

        textarea
          border: 1px solid $primary-color
          border-radius: 10px

        .post_button
          background-color: $primary-color
          color: white
          font-size: 2rem
          border: 0
          border-radius: 60px

      .item
        .name
          color: #42A5AA
          font-weight: 600

        p.comment
          border: 1px solid $primary-color
          background-color: #eee
          border-radius: 10px
          padding: 0.5rem
          font-size: 1rem
          font-weight: 400

        .time
          text-align: right
          font-size: 0.9rem
          font-weight: 400
</style>
